


















section{
  text-align: center;
  margin-top: 120px;
}
@media (max-width: 767px){
  section{
    img{
      width: 100%;
    }
  }
}
